<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.tipo_identificacion.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.tipo_identificacion.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.tipo_identificacion.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex flex-row-reverse align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon dark left>
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  v-model="filters.selected.query"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="filters.selected.query"
          >
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <tipo-identificacion-add />
    <tipo-identificacion-edit />
    <tipo-identificacion-detail />
    <tipo-identificacion-delete />
  </v-container>
</template>

<script>
    export default {
      name: "TipoIdentificacion",
      components: {
        TipoIdentificacionAdd: () => import('./TipoIdentificacionAdd'),
        TipoIdentificacionEdit: () => import('./TipoIdentificacionEdit'),
        TipoIdentificacionDetail: () => import('./TipoIdentificacionDetalle'),
        TipoIdentificacionDelete: () => import('./TipoIdentificacionDelete')
      },
      data () {
        return {
          headers: [
            {
              text: this.$t('app.headers.id'),
              value: 'id',
            },
            {
              text: this.$t('app.headers.name'),
              value: 'nombre',
            },
            {
              text: this.$t('app.headers.description'),
              value: 'descripcion',
            },
            {
              text: '',
              value: 'actions',
              sortable: false
            }
          ],
          items: [],
          filters: {
            selected: {
              query: ''
            }
          }
        }
      },
      mounted() {
        this.init()

        EventBus.$on('reload-items', () => {
          this.init()
        })
      },
      methods: {
        async init () {
          this.toggleLoader()

          await this.$http.get(route('v1/id-type'))
          .then(response => {
            if (response.body.code === 200) {
              this.items = response.body.data
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

          this.toggleLoader()
        },
        openItemAdd () {
          EventBus.$emit('tipo-identificacion-add')
        },
        openItemEdit (item) {
          EventBus.$emit('tipo-identificacion-edit', item)
        },
        openItemDetail (item) {
          EventBus.$emit('tipo-identificacion-detail', item)
        },
        openItemDelete (item) {
          EventBus.$emit('tipo-identificacion-delete', item)
        },
      }
    }
</script>

<style scoped>

</style>
